var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MemoMatter" },
    [
      _c(
        "div",
        { staticClass: "date-matter" },
        _vm._l(_vm.memoMatterList.list, function (matter) {
          return _c(
            "div",
            { key: matter.id },
            [
              _c(
                "a-popover",
                {
                  attrs: {
                    trigger: "hover",
                    placement: "leftTop",
                    "overlay-class-name": "matter-popover",
                  },
                  model: {
                    value: matter.propoVisible,
                    callback: function ($$v) {
                      _vm.$set(matter, "propoVisible", $$v)
                    },
                    expression: "matter.propoVisible",
                  },
                },
                [
                  _c("template", { slot: "content" }, [
                    _c("div", { staticClass: "popover-title" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(matter.title)),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(matter.content)),
                      ]),
                    ]),
                    _c("div", { staticClass: "popover-content" }, [
                      _c(
                        "div",
                        { staticClass: "content-item flex-align-center" },
                        [
                          _c("a-icon", {
                            staticClass: "item-icon",
                            attrs: { type: "clock-circle" },
                          }),
                          _c("span", { staticClass: "item-title" }, [
                            _vm._v("开始时间"),
                          ]),
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(matter.memoDateValue)),
                          ]),
                        ],
                        1
                      ),
                      matter.durationValue
                        ? _c(
                            "div",
                            { staticClass: "content-item flex-align-center" },
                            [
                              _c("a-icon", {
                                staticClass: "item-icon",
                                attrs: { type: "clock-circle" },
                              }),
                              _c("span", { staticClass: "item-title" }, [
                                _vm._v("时长"),
                              ]),
                              _c("span", { staticClass: "item-text" }, [
                                _vm._v(_vm._s(matter.durationValue)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "content-item flex-align-center" },
                        [
                          _c("a-icon", {
                            staticClass: "item-icon",
                            attrs: { type: "clock-circle" },
                          }),
                          _c("span", { staticClass: "item-title" }, [
                            _vm._v("提醒"),
                          ]),
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(matter.remindValue)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "content-item flex-align-center" },
                        [
                          _c("a-icon", {
                            staticClass: "item-icon",
                            attrs: { type: "clock-circle" },
                          }),
                          _c("span", { staticClass: "item-title" }, [
                            _vm._v("重复"),
                          ]),
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(matter.repeatTypeValue)),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "popover-bottom" },
                      [
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.editMatter(matter)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.delMatter(matter)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "matter flex-align-center" }, [
                    +matter.allDay
                      ? _c("span", { staticClass: "matter-time" }, [
                          _vm._v("全天"),
                        ])
                      : _c("div", { staticClass: "matter-time" }, [
                          _c("span", [_vm._v(_vm._s(matter.startTime))]),
                          _c("span", [_vm._v(_vm._s(matter.endTime))]),
                        ]),
                    _c("div", { staticClass: "matter-content" }, [
                      _c(
                        "div",
                        { staticClass: "matter-title flex-align-center" },
                        [
                          _c("span", { staticClass: "type-color success" }),
                          _c("span", [_vm._v(_vm._s(matter.title))]),
                        ]
                      ),
                      _c("div", { staticClass: "matter-text" }, [
                        _vm._v(" " + _vm._s(matter.content) + " "),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.editVisible,
            "ok-text": "确认",
            size: "normal",
            closable: "",
            type: "info",
            title: "你正在更改重复日程",
          },
          on: {
            ok: _vm.handleEdit,
            cancel: function ($event) {
              _vm.editVisible = false
            },
          },
        },
        [
          [
            _c(
              "a-radio-group",
              {
                attrs: { name: "radioGroup" },
                model: {
                  value: _vm.editType,
                  callback: function ($$v) {
                    _vm.editType = $$v
                  },
                  expression: "editType",
                },
              },
              [
                _c("a-radio", { style: _vm.radioStyle, attrs: { value: 1 } }, [
                  _vm._v("仅此日程"),
                ]),
                _c("a-radio", { attrs: { value: 3 } }, [
                  _vm._v("此日程及后续日程"),
                ]),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.delVisible,
            "ok-text": "确认",
            size: "normal",
            closable: "",
            type: "info",
            title: "您正在删除日程",
          },
          on: {
            ok: _vm.handleDel,
            cancel: function ($event) {
              _vm.delVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "model-content" },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { name: "radioGroup" },
                  model: {
                    value: _vm.delType,
                    callback: function ($$v) {
                      _vm.delType = $$v
                    },
                    expression: "delType",
                  },
                },
                _vm._l(_vm.scheduleList, function (item) {
                  return _c(
                    "a-radio",
                    {
                      key: item.value,
                      style:
                        item.value !== 3 ? _vm.radioStyle : _vm.lastRadioStyle,
                      attrs: { value: item.value },
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }